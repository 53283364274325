




































































































import {
  BlockchainWalletType,
  BlockchainWalletItem,
  BlockchainWalletData
} from '@/api/schema';
import { PropType } from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import {
  createWallet,
  deleteWallet,
  editWallet
} from '@/api/BlockchainWallet';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { roundNumber } from '@/helpers/roundNumber';

import TokenManagementWalletDialog from './TokenManagementWalletDialog.vue';

enum ETypeWalletDialog {
  CREATE = 'create',
  EDIT = 'edit'
}

export default {
  name: 'TokenManagementWalletCard',
  components: {
    TokenManagementWalletDialog
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<BlockchainWalletType>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  data(): any {
    return {
      walletDialog: {
        action: ETypeWalletDialog.CREATE,
        wallet: null,
        open: false
      },
      showSettingDialog: false
    };
  },

  computed: {
    ...mapState('BlockchainWallet', ['wallets', 'loading']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),

    walletList(): BlockchainWalletItem[] {
      return this.wallets
        .filter((wallet: BlockchainWalletItem) => wallet.type === this.type)
        .sort(
          (a: BlockchainWalletItem, b: BlockchainWalletItem) => a.id - b.id
        );
    }
  },

  methods: {
    ...mapActions('BlockchainWallet', ['getWallet', 'activateWallet']),

    roundNumber,

    disabledWallet(current: BlockchainWalletItem): boolean {
      if (!this.isSuperAdmin) return true;

      if (this.disabled || !current.id || this.loading.wallets) return true;

      if (current.active) return false;

      return this.walletList.some(
        (wallet: BlockchainWalletItem) => wallet.active
      );
    },

    handlerChangeStatus(id: number, active: boolean): void {
      this.activateWallet({
        id,
        active: !active,
        operatorId: this.operatorId
      });
    },

    async handlerDeleteWallet(id: number): Promise<void> {
      await deleteWallet(id)
        .then(() => {
          this.getWallet(this.operatorId);
          this.$toast.success('Wallet has been delete');
        })
        .catch((e) => {
          this.$toast.success(e);
        });
    },

    handlerOpenWalletDialog(wallet: BlockchainWalletItem | null): void {
      this.walletDialog = {
        wallet,
        action: wallet ? ETypeWalletDialog.EDIT : ETypeWalletDialog.CREATE,
        open: true
      };
    },

    handlerCloseWalletDialog(): void {
      this.walletDialog.open = false;

      setTimeout(() => {
        this.walletDialog.wallet = null;
        this.walletDialog.action = ETypeWalletDialog.CREATE;
      }, 300);
    },

    editWallets: function (data: BlockchainWalletData): void {
      const handler = this.action === 'edit' ? editWallet : createWallet;

      (handler(data) as Promise<any>)
        .then(() => {
          this.$toast.success(`Wallet has been ${this.action}`);
          this.getWallet(this.operatorId);
        })
        .catch((e: AxiosError) => errorToastMessage(e))
        .finally(this.handlerCloseWalletDialog);
    },

    openSettingDialog(): void {
      this.showSettingDialog = true;
    },

    closeSettingDialog(): void {
      this.showSettingDialog = false;
    }
  }
};
