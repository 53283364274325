
















































































































































import { mapGetters } from 'vuex';
import { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import {
  buybackSettings,
  saveBuybackSettings,
  setBuybackVolume
} from '@/api/BlockchainWallet';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { BuybackSettings, BuybackType } from '@/api/schema';
import type { AxiosError } from 'axios';

export default {
  name: 'TokenManagementBuybackSettings',
  props: {
    showDialog: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data: (): any => ({
    processing: false,
    formIsValid: true,
    buybackType: [
      { label: 'Linear', value: BuybackType.linear },
      { label: 'Random', value: BuybackType.random }
    ],
    buybackSettings: {
      buybackCoefficient: null,
      buybackType: BuybackType.linear,
      executionTime: '',
      walletThreshold: null,
      walletTopUpAmount: null,
      buybackVolume: null
    },
    timeModal: false
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    rules(): unknown {
      return {
        buybackCoefficient: [
          vr.required,
          vr.validNumber,
          (v) => vr.gte(v, 0),
          (v) => vr.lte(v, 100)
        ],
        buybackType: [vr.required],
        executionTime: [vr.required, vr.time],
        thresholdRules: [vr.required, vr.validNumber, (v) => vr.gte(v, 0)],
        buybackVolume: [vr.required, vr.validNumber, (v) => vr.gte(v, 0)],
        walletBnbThreshold: [
          vr.required,
          vr.validNumber,
          (v) => vr.gte(v, 0)
        ],
        wallerBnbTopUpAmount: [
          vr.required,
          vr.validNumber,
          (v) => vr.gte(v, 0)
        ]
      };
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getSettings();
        }
      },
      immediate: true
    },
    showDialog(status: boolean): void {
      if (!status) {
        this.getSettings();
      }
    }
  },
  methods: {
    getSettings(): void {
      this.processing = true;
      buybackSettings()
        .then((data: BuybackSettings) => {
          this.buybackSettings = data;
          this.$refs.form?.resetValidation();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.processing = false;
        });
    },
    saveSettings(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (!this.formIsValid) return;
        this.processing = true;
        Promise.all([
          saveBuybackSettings(this.buybackSettings),
          setBuybackVolume(this.buybackSettings.buybackVolume)
        ])
          .then(([data]: [BuybackSettings, any]) => {
            this.buybackSettings = data;
            this.$toast.success('Buyback Wallet settings are updated');
            this.$emit('closeDialog');
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
