var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"token-management-trading-details"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openModal = true}}},[_vm._v("Details")]),_c('BaseDialog',{attrs:{"title":"Details","max-width":"1226"},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('v-data-table-with-pagination',{attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.loading.table,"server-items-length":_vm.totalElements,"options":_vm.options,"disable-sort":"","height":"554px","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.deposit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.deposit,'0,0.[00]')))]}},{key:"item.depositPancake",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.depositPancake,'0,0.[00]')))]}},{key:"item.withdraw",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.withdrawal,'0,0.[00]')))]}},{key:"item.withdrawPancake",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.withdrawalPancake,'0,0.[00]')))]}}])})]},proxy:true}]),model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-extended-data-picker',{attrs:{"max-days":31,"range":"","max-yesterday":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"loading":_vm.loading.csv,"outlined":"","color":"primary"},on:{"click":_vm.getReport}},[_vm._v("CSV"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-download-outline")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }