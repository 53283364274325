














































































import { mapGetters, mapState } from 'vuex';
import { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import {
  liquiditySettings,
  saveLiquiditySettings
} from '@/api/BlockchainWallet';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';

export default {
  name: 'TokenManagementLiquiditySettings',
  props: {
    showDialog: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data: (): any => ({
    processing: false,
    formIsValid: true,
    liquiditySettings: {
      walletUSDThreshold: null,
      walletTokenThreshold: null,
      walletUSDTopUpAmount: null,
      walletTokenTopUpAmount: null,
      walletBnbThreshold: null,
      walletBnbTopUpAmount: null
    },
    rules: []
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    enableRules(): boolean {
      return !!Object.values(this.liquiditySettings).join('');
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getSettings();
        }
      },
      immediate: true
    },
    showDialog(status: boolean): void {
      if (!status) {
        this.getSettings();
      }
    },
    enableRules(): void {
      this.rules = [];
    }
  },
  methods: {
    getSettings(): void {
      this.processing = true;
      liquiditySettings()
        .then((data: any) => {
          this.liquiditySettings = data;
          this.$refs.form?.resetValidation();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.processing = false;
        });
    },
    saveSettings(): void {
      this.rules = this.enableRules ? [vr.required, vr.validNumber] : [];
      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (!this.formIsValid) return;
        this.processing = true;
        saveLiquiditySettings(this.liquiditySettings)
          .then((data: any) => {
            this.liquiditySettings = data;
            this.$toast.success('Liquidity Wallet settings are updated');
            this.$emit('closeDialog');
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
