















































import { mapActions, mapGetters } from 'vuex';
import { BlockchainTransaction } from '@/api/schema';
import { PropType } from 'vue';
import debounce from 'lodash/debounce';
import formatDate from '@/helpers/formatDate';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'TokenManagementTransactionsTable',
  props: {
    type: {
      type: String as PropType<string>,
      default: (): string => 'trading',
      required: true
    },
    date: {
      type: String as PropType<string>,
      default: (): string => null,
      required: false
    },
    hideColumns: {
      type: Array as PropType<string[]>,
      default: (): string[] => []
    }
  },
  data(): any {
    return {
      options: { ...defaultOptions }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    tableHeaders(): { [key: string]: string | boolean }[] {
      return [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Trade', value: 'trade', sortable: false },
        { text: 'Wallet', value: 'walletHash', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Fee', value: 'fee', sortable: false },
        { text: 'Hash', value: 'hash', sortable: false }
      ].filter((header) => !this.hideColumns.includes(header.value));
    },
    transactionsType(): string {
      return this.type.toLowerCase();
    },
    transactionsData(): any {
      return this.$store.state.BlockchainWallet.transactions[
        this.transactionsType
      ];
    },
    transactions(): BlockchainTransaction[] {
      return this.transactionsData.transactions;
    },
    hasNext(): boolean {
      return this.transactionsData?.hasNext;
    },
    total(): number {
      return this.transactionsData?.total;
    },
    loading(): BlockchainTransaction[] {
      return this.$store.state.BlockchainWallet.loading[
        `${this.transactionsType}Transactions`
      ];
    }
  },
  watch: {
    options: {
      handler(): any {
        this.load();
      },
      deep: true
    },
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.options = { ...defaultOptions };
          this.load();
        }
      },
      immediate: true
    },
    date: {
      handler(date: string): void {
        this.load();
        this.getTransactionsSummary({
          type: this.transactionsType,
          date
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('BlockchainWallet', [
      'getTransactions',
      'getTransactionsSummary'
    ]),
    load: debounce(function () {
      if (!this.type || !this.date || !this.operatorId) {
        return;
      }

      this.getTransactions({
        type: this.transactionsType,
        date: this.date,
        page: this.options.page - 1,
        size: this.options.itemsPerPage
      });
    }, 200),
    formatDate
  }
};
