







































































































































import { PropType } from 'vue';
import { mapState } from 'vuex';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import VFieldTimePicker from '@/components/VFieldTimePicker.vue';
import * as vr from '@/helpers/validation';
import type { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { updateAutoSellTokenBotInfo } from '@/api/AutoSellTokenBot';
import {
  ValidationRules,
  SelectItem,
  TAutoSellTokenBotInfoData
} from '@/api/schema';
import { fromPercents, toPercents } from '@/helpers/decimalsHelpers';

const daysList = [
  { text: 'Monday', value: 'MONDAY' },
  { text: 'Tuesday', value: 'TUESDAY' },
  { text: 'Wednesday', value: 'WEDNESDAY' },
  { text: 'Thursday', value: 'THURSDAY' },
  { text: 'Friday', value: 'FRIDAY' },
  { text: 'Saturday', value: 'SATURDAY' },
  { text: 'Sunday', value: 'SUNDAY' }
];

interface AutoSellTokenBotSetUpDataState {
  daysList: Array<SelectItem>;

  workingDays: string[];
  startTime: string;
  endTime: string;
  percentageOfMovement: string;
  loverPriceLimit: string;
  minPriceImpact: string;
  maxPriceImpact: string;
  dailyLimit: string;

  processing: boolean;
  formIsValid: boolean;
}

export default {
  name: 'TokenManagementAutoSellTokenBotSetUp',
  components: {
    VExtendedDataPicker,
    VFieldTimePicker,
    Tooltip
  },
  props: {
    tokenBotData: {
      type: Object as PropType<TAutoSellTokenBotInfoData>,
      required: true
    },
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data: (): AutoSellTokenBotSetUpDataState => ({
    daysList,

    workingDays: [],
    startTime: '',
    endTime: '',
    percentageOfMovement: '',
    loverPriceLimit: '',
    minPriceImpact: '',
    maxPriceImpact: '',
    dailyLimit: '',

    processing: false,
    formIsValid: true
  }),
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    formattedPercentageOfMovement: {
      get(): number {
        return toPercents(this.percentageOfMovement);
      },
      set(value: number): void {
        this.percentageOfMovement = fromPercents(value);
      }
    },
    formattedMinPriceImpact: {
      get(): number {
        return toPercents(this.minPriceImpact);
      },
      set(value: number): void {
        this.minPriceImpact = fromPercents(value);
      }
    },
    formattedMaxPriceImpact: {
      get(): number {
        return toPercents(this.maxPriceImpact);
      },
      set(value: number): void {
        this.maxPriceImpact = fromPercents(value);
      }
    },
    rules(): ValidationRules {
      return {
        workingDays: [(v) => (!!v && !!v.length) || `Value is required`],
        startTime: [],
        endTime: [],
        percentageOfMovement: [
          vr.required,
          (v: string) => vr.validNumber(v, 'Value is not valid'),
          vr.positiveNumber,
          (v) => vr.lte(v, 100),
          (v) => vr.gte(v, 0.0001),
          (v) => vr.maxPrecision(v, 4)
        ],
        loverPriceLimit: [
          vr.required,
          (v: string) => vr.validNumber(v, 'Value is not valid')
        ],
        minPriceImpact: [
          vr.required,
          (v: string) => vr.validNumber(v, 'Value is not valid'),
          vr.positiveNumber,
          (v) => vr.lte(v, 100),
          (v) => vr.gte(v, 0.0001),
          (v) => vr.maxPrecision(v, 4)
        ],
        maxPriceImpact: [
          vr.required,
          (v: string) => vr.validNumber(v, 'Value is not valid'),
          vr.positiveNumber,
          (v) => vr.lte(v, 100),
          (v) => vr.gte(v, 0.0001),
          (v) => vr.maxPrecision(v, 4)
        ],
        dailyLimit: [
          vr.required,
          (v: string) => vr.validNumber(v, 'Value is not valid')
        ]
      };
    }
  },
  watch: {
    showDialog: 'watchDialog'
  },
  methods: {
    watchDialog(isShow: boolean): void {
      if (!isShow) return;

      this.workingDays = this.tokenBotData.workingDays;
      this.startTime = DateTime.fromFormat(
        this.tokenBotData.startTime,
        'HH:mm:ss'
      ).toFormat('HH:mm');
      this.endTime = DateTime.fromFormat(
        this.tokenBotData.endTime,
        'HH:mm:ss'
      ).toFormat('HH:mm');
      this.percentageOfMovement = this.tokenBotData.percentageOfMovement;
      this.loverPriceLimit = this.tokenBotData.loverPriceLimit;
      this.minPriceImpact = this.tokenBotData.minPriceImpact;
      this.maxPriceImpact = this.tokenBotData.maxPriceImpact;
      this.dailyLimit = this.tokenBotData.dailyLimit;
    },
    closeDialog(isUpdateData: boolean = false): void {
      this.$emit('closeDialog', isUpdateData);
    },
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (!this.formIsValid) return;

        this.processing = true;
        debugger;

        const payload: TAutoSellTokenBotInfoData = {
          ...this.tokenBotData,

          workingDays: this.workingDays,
          startTime: DateTime.fromFormat(this.startTime, 'HH:mm').toFormat(
            'HH:mm:ss'
          ),
          endTime: DateTime.fromFormat(this.endTime, 'HH:mm').toFormat(
            'HH:mm:ss'
          ),
          percentageOfMovement: this.percentageOfMovement,
          loverPriceLimit: this.loverPriceLimit,
          minPriceImpact: this.minPriceImpact,
          maxPriceImpact: this.maxPriceImpact,
          dailyLimit: this.dailyLimit
        };

        updateAutoSellTokenBotInfo(payload)
          .then((): void => {
            this.$toast.success(
              'AutoSell Token Bot data saving was successful'
            );
            this.closeDialog(true);
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
