
















































import { mapState } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import type { PropType } from 'vue';

export default {
  name: 'DashboardDepositDialog',
  components: {
    QrcodeVue
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    address: {
      type: String as PropType<string>,
      default: (): string => null
    }
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    copyToClipBoard
  }
};
