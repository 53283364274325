






















































import type { PropType } from 'vue';
import { startCase, camelCase } from 'lodash';
import * as vr from '@/helpers/validation';
import type { BlockchainWalletItem } from '@/api/schema';

const defaultEditValues = {
  privateKey: '******************************',
  publicKey: ''
};

export default {
  name: 'TokenManagementWalletDialog',
  props: {
    wallet: {
      type: Object as PropType<BlockchainWalletItem>,
      default: (): null => null,
      required: false
    },
    action: {
      type: String as PropType<string>,
      default: (): string => 'Create',
      required: true
    },
    showDialog: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    type: {
      type: String as PropType<string>,
      default: (): string => ''
    }
  },
  data(): any {
    return {
      timeModal: false,
      walletData: {
        ...defaultEditValues
      },
      formIsValid: true,
      privateKeyTouched: false
    };
  },
  computed: {
    header(): string {
      return `${this.action} wallet`;
    },
    privateKey: {
      get(): string {
        return this.wallet && this.action === 'edit'
          ? this.walletData.privateKey
          : '';
      },
      set(v: string): void {
        this.walletData.privateKey = v;
      }
    },
    publicKey: {
      get(): string {
        return this.wallet && this.action === 'edit'
          ? this.wallet.publicKey
          : '';
      },
      set(v: string): void {
        this.walletData.publicKey = v;
      }
    },
    rules(): unknown {
      return {
        privateKey: this.privateKeyTouched
          ? [vr.required, (v) => vr.length(v, 64)]
          : [],
        publicKey: [vr.required, vr.metaMaskPublicKey]
      };
    },
    disabledSaveButton(): boolean {
      return !this.formIsValid || !this.privateKeyTouched;
    }
  },
  watch: {
    showDialog(show: boolean): void {
      if (!show) {
        this.walletData = { ...defaultEditValues };
        this.privateKeyTouched = false;
      }
    },
    wallet(wallet: BlockchainWalletItem): void {
      if (!wallet?.publicKey) return;

      this.publicKey = wallet.publicKey;
    }
  },
  methods: {
    transformText(str: string): string {
      return startCase(camelCase(str));
    },
    onPrivateKeyFocused(): void {
      if (this.privateKeyTouched) {
        return;
      }

      this.privateKeyTouched = true;
      this.walletData.privateKey = null;
    },
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        try {
          if (this.formIsValid) {
            this.walletData =
              this.action === 'edit'
                ? Object.assign(this.walletData, {
                    id: this.wallet.id
                  })
                : Object.assign(this.walletData, {
                    type: this.type
                  });
            this.$emit('submit', this.walletData);
          }
        } catch (err) {
          this.$toast('Error validation');
        }
      });
    }
  }
};
