




























































import { mapGetters, mapState } from 'vuex';
import TokenManagementAutoSellTokenBotSetUp from '@/views/DashboardViews/token-management/token-management-auto-sell-token-bot/TokenManagementAutoSellTokenBotSetUp.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { AxiosError } from 'axios';
import { TAutoSellTokenBotInfoData } from '@/api/schema';
import {
  getAutoSellTokenBotInfo,
  getAutoSellTokenBotTotalSold,
  updateAutoSellTokenBotInfo
} from '@/api/AutoSellTokenBot';
import { tokensToCurrency } from '@/helpers/currencyHelper';

export default {
  name: 'TokenManagementAutoSellTokenBotInfo',
  components: {
    TokenManagementAutoSellTokenBotSetUp,
    Tooltip
  },
  data(): any {
    return {
      tokenBotData: {} as TAutoSellTokenBotInfoData,
      tokenBotTransactionsData: null,
      loading: {
        botData: false,
        tokensData: false,
        status: false
      },
      isShowSetUpDialog: false
    };
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getTokenBotInfo();
          this.getTokenBotTokensInfo();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    tokenBotAmountValue(): number | null {
      return tokensToCurrency(this.tokenBotSoldTokensValue);
    },
    tokenBotSoldTokensValue(): number | null {
      return this.tokenBotTransactionsData.soldTokens;
    }
  },
  methods: {
    getTokenBotInfo(): void {
      this.loading.botData = true;

      getAutoSellTokenBotInfo()
        .then((data: TAutoSellTokenBotInfoData) => {
          this.tokenBotData = data;
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.loading.botData = false;
        });
    },
    getTokenBotTokensInfo(): void {
      this.loading.tokensData = true;

      getAutoSellTokenBotTotalSold()
        .then((data: number) => {
          this.tokenBotTransactionsData = {
            soldTokens: data
          };
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.loading.tokensData = false;
        });
    },
    switchHandler(switchStatus: boolean): void {
      this.loading.status = true;

      updateAutoSellTokenBotInfo({
        ...this.tokenBotData,
        enable: switchStatus
      })
        .then((): void => {
          this.$toast.success(
            `AutoSell Token Bot has been turned ${
              switchStatus ? 'on' : 'off'
            }`
          );
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.loading.status = false;
          this.getTokenBotInfo();
        });
    },
    dialogHandler(dialogValue: boolean, isUpdateData: boolean = false): void {
      this.isShowSetUpDialog = dialogValue;

      if (isUpdateData) this.getTokenBotInfo();
    }
  }
};
