















import { mapState, mapActions } from 'vuex';
import TokenManagementBuybackDetails from './TokenManagementBuybackDetails.vue';
import type { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';
import { usdToOperatorCurrency } from '@/helpers/currencyHelper';
import formatDate from '@/helpers/formatDate';
import { DateTime } from 'luxon';

export default {
  name: 'TokenManagementBuybackVolume',
  components: {
    TokenManagementBuybackDetails
  },
  computed: {
    ...mapState('BlockchainWallet', ['stats']),
    ...mapState('TokensInfo', {
      rateCurrencyTicker: (state: TokensInfoState) =>
        state.tokenPrice.rateCurrencyTicker
    }),
    monthlyAmount(): string {
      return this.prepareData(this.stats.monthlyAmount);
    },
    monthlyEmission(): string {
      return this.prepareData(this.stats.monthlyEmission);
    },
    monthlyDates(): string {
      const now = DateTime.now();

      const format = 'd LLL';

      const start = formatDate(
        now.startOf('month').toISODate(),
        format,
        true
      );
      const end = formatDate(now.toISODate(), format, true);

      return [start, end].join(' - ');
    }
  },
  methods: {
    ...mapActions('BlockchainWallet', ['getBuybackStats']),
    prepareData(data: string): string {
      return `${this.$options.filters.numeralSpaces(
        usdToOperatorCurrency(+data),
        `0,0.[00]`
      )} ${this.rateCurrencyTicker}`;
    }
  },

  mounted(): void {
    this.getBuybackStats();
  }
};
