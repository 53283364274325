

















































import * as vr from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import { addUsdtLiquidityPool } from '@/api/BlockchainWallet';

interface AddLiquidityDataState {
  processing: boolean;
  liquidityData: LiquidityData;
  formIsValid: boolean;
}

interface LiquidityData {
  amountUsdt: string;
}

export default {
  name: 'SuperAdminTokenManagementAddLiquidity',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data: (): AddLiquidityDataState => ({
    processing: false,
    liquidityData: {} as LiquidityData,
    formIsValid: true
  }),
  computed: {
    rules(): unknown {
      return [vr.required, vr.validNumber, (v) => vr.gte(v, 0)];
    }
  },
  methods: {
    closeDialog(): void {
      this.$emit('closeDialog');
      this.liquidityData = {};
    },
    addLiquidity(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (!this.formIsValid) return;

        this.processing = true;

        addUsdtLiquidityPool(this.liquidityData.amountUsdt)
          .then((): void => {
            this.$toast.success('Add Liquidity is success');
            this.closeDialog();
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
