
























































































import { mapGetters, mapState } from 'vuex';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import TokenManagementAddLiquidity from './TokenManagementAddLiquidity.vue';
import TokenManagementRemoveLiquidity from './TokenManagementRemoveLiquidity.vue';
import TokenManagementCreateLiquidity from './TokenManagementCreateLiquidity.vue';
import TokenManagementLiquidityPoolTable from './TokenManagementLiquidityPoolTable.vue';
import { ClientsInfoState } from '@/store/modules/clients-info/ClientsInfoState';
import {
  getLiquidityInfo,
  getLiquidityTransactionsSum
} from '@/api/BlockchainWallet';
import { AxiosError } from 'axios';

enum EDialogKeys {
  CREATE_LIQUIDITY = 'CREATE_LIQUIDITY',
  ADD_LIQUIDITY = 'ADD_LIQUIDITY',
  REMOVE_LIQUIDITY = 'REMOVE_LIQUIDITY'
}

export default {
  name: 'TokenManagementLiquidityPoolTab',
  components: {
    TokenManagementLiquidityPoolTable,
    TokenManagementCreateLiquidity,
    TokenManagementRemoveLiquidity,
    TokenManagementAddLiquidity
  },
  data(): any {
    return {
      EDialogKeys,
      showStateDialogs: {
        [EDialogKeys.CREATE_LIQUIDITY]: false,
        [EDialogKeys.ADD_LIQUIDITY]: false,
        [EDialogKeys.REMOVE_LIQUIDITY]: false
      },
      liquidityPoolData: {
        usdt: 0,
        token: 0
      },
      liquidityTransactionSum: 0,
      isLoading: false
    };
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getLiquidityInfo();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('SuperAdmin', ['isLiquidityPoolCreated']),
    ...mapState('ClientsInfo', {
      pancakeSwapUrl: (state: ClientsInfoState) =>
        state.clientsInfo?.pancakeSwapUrl
    }),
    disabled(): boolean {
      return (
        !this.$role.can.update('token_manage liquidity_pool') ||
        this.isLoading
      );
    }
  },
  methods: {
    handlerChangeStateDialogs(dialogKey: EDialogKeys, value: boolean): void {
      this.showStateDialogs[dialogKey] = value;
    },
    getLiquidityInfo(): void {
      this.isLoading = true;

      Promise.all([getLiquidityInfo(), getLiquidityTransactionsSum()])
        .then(([liquidityInfo, liquidityTransactionsSum]) => {
          this.liquidityPoolData.usdt = liquidityInfo.usdt || 0;
          this.liquidityPoolData.token = liquidityInfo.token || 0;

          this.liquidityTransactionSum = liquidityTransactionsSum;
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.isLoading = false;
        });
    }
  }
};
