






















































































import { mapGetters, mapState } from 'vuex';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import formatDate from '@/helpers/formatDate';
import { doDownload } from '@/helpers/download';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { DateTime } from 'luxon';
import {
  getAutoSellTokenBotTransactions,
  getAutoSellTokenBotTransactionsCsv
} from '@/api/AutoSellTokenBot';
import {
  IAutoSellTokenBotTransactionsListResponse,
  IAutoSellTokenBotTransactionsRequestParams,
  TAutoSellTokenBotTransactionItem
} from '@/api/schema';

const defaultDates = {
  from: DateTime.utc().minus({ months: 1 }).toFormat('yyyy-LL-dd'),
  to: DateTime.utc().toFormat('yyyy-LL-dd')
};

export default {
  name: 'TokenManagementAutoSellTokenBotTransactions',
  components: {
    VExtendedDataPicker
  },
  data(): any {
    return {
      dates: { ...defaultDates },
      loading: {
        list: false,
        csv: false
      },
      transactions: [] as TAutoSellTokenBotTransactionItem[],
      page: 1,
      hasNext: false,
      lastDate: [],
      headers: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Wallet', value: 'wallet', width: '25%', sortable: false },
        { text: `Amount Tokens`, value: 'amount', sortable: false },
        // columns are temporarily hidden DEV-6895
        // { text: `Start Price`, value: 'startPrice', sortable: false },
        // { text: `Sales Price`, value: 'salesPrice', sortable: false },
        { text: 'Hash', value: 'hash', sortable: false }
      ]
    };
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.resetPagination();
          this.getTransactionsData();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    paginationLength(): number {
      return this.page + (this.hasNext ? 1 : 0);
    }
  },
  methods: {
    formatDate,
    textOverflowMiddle,
    copyToClipBoard,
    getFormatDate(date: string): string {
      return formatDate(
        date.slice(0, date.lastIndexOf('.')),
        'yy.MM.dd HH:mm:ss',
        true,
        false
      );
    },
    onDateChange(dates: string[]): void {
      this.dates = dates;
      this.resetPagination();
      this.getTransactionsData();
    },
    resetPagination(): void {
      this.page = 1;
      this.lastDate = [];
    },
    changePage(page: number): void {
      const listLength = this.transactions?.length;
      const date =
        page === 1
          ? null
          : this.lastDate[page] || this.transactions[listLength - 1].date;

      this.getTransactionsData(date).then((): void => {
        this.page = page;
        page === 1 ? (this.lastDate = []) : (this.lastDate[page] = date);
      });
    },
    getTransactionsData(date?: string): Promise<void> {
      this.loading.list = true;

      if (!this.operatorId) return;

      let params: IAutoSellTokenBotTransactionsRequestParams = {
        from: `${this.dates.from}T00:00:00.000`,
        to: date || `${this.dates.to}T23:59:59.999`,
        size: 10
      };

      return getAutoSellTokenBotTransactions(params)
        .then((data: IAutoSellTokenBotTransactionsListResponse): void => {
          this.transactions = data.content;
          this.hasNext = !data.last;
        })
        .catch((error) => {
          this.transactions = [];
          this.hasNext = false;

          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading.list = false;
        });
    },
    async getTransactionsCsv(): Promise<void> {
      try {
        this.loading.csv = true;

        const data = await getAutoSellTokenBotTransactionsCsv({
          from: `${this.dates.from}T00:00:00.000`,
          to: `${this.dates.to}T23:59:59.999`
        });

        const url = window.URL.createObjectURL(new Blob([data.content]));

        doDownload(
          url,
          `auto-sell-token-bot-transaction-report_${this.date}.csv`
        );
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loading.csv = false;
      }
    }
  }
};
