














































import { mapActions, mapGetters, mapState } from 'vuex';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { getOperatorWallet } from '@/api/OperatorPayments';
import { operatorsMerchantWithdraw } from '@/api/SuperadminCustody';
import { roundNumber } from '@/helpers/roundNumber';
import type { OperatorWalletItem } from '@/api/schema';

import TokenManagementContractAddress from './TokenManagementContractAddress.vue';
import TokenManagementDepositDialog from './TokenManagementDepositDialog.vue';
import TokenManagementWithdrawDialog from './TokenManagementWithdrawDialog.vue';

import type { MerchantTokenWithdraw } from '@/api/schema';

export default {
  name: 'TokenManagementMasterWallet',
  components: {
    TokenManagementContractAddress,
    TokenManagementDepositDialog,
    TokenManagementWithdrawDialog
  },
  data: (): unknown => ({
    depositWallet: null,
    dialogs: {
      deposit: false,
      withdraw: false
    }
  }),
  computed: {
    ...mapState('Dashboard', ['operatorWallet']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('TokensInfo', ['smartContractAddressBSC']),
    ...mapGetters('Onboarding', ['operatorId']),

    wallet(): OperatorWalletItem {
      if (!this.operatorWallet) return;

      return this.operatorWallet.MASTER;
    }
  },
  watch: {
    operatorId: {
      handler(id: string): void {
        if (!id) return;

        this.getOperator();
        this.getDepositWallet();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Dashboard', ['withdrawTokens', 'getOperator']),
    roundNumber,
    getDepositWallet(): void {
      getOperatorWallet()
        .then((result) => {
          this.depositWallet = result.address;
        })
        .catch((): void => {
          errorToastMessage(null);
        });
    },
    async withdrawDialogHandler(
      requestParams: MerchantTokenWithdraw
    ): Promise<void> {
      try {
        await operatorsMerchantWithdraw(
          this.operatorId,
          1, // BSC
          requestParams
        );
      } catch (error) {
        console.error(error);
        errorToastMessage(error);
      } finally {
        this.dialogs.withdraw = false;
      }
    }
  }
};
