























































import { mapGetters, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import {
  getAutoSellTokenBotSettings,
  saveAutoSellTokenBotSettings
} from '@/api/AutoSellTokenBot';
import { TAutoSellTokenBotSettings } from '@/api/schema';

export default {
  name: 'TokenManagementAutoSellTokenBotSettings',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: (): any => ({
    processing: false,
    formIsValid: true,
    autoSellTokenBotSettings: {
      walletThreshold: null,
      walletTopUpAmount: null
    },
    rules: []
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    enableRules(): boolean {
      return !!Object.values(this.autoSellTokenBotSettings).join('');
    }
  },
  watch: {
    showDialog(status: boolean): void {
      if (status) {
        this.getSettings();
      }
    },
    enableRules(): void {
      this.rules = [];
    }
  },
  methods: {
    getSettings(): void {
      this.processing = true;

      getAutoSellTokenBotSettings()
        .then((data: TAutoSellTokenBotSettings) => {
          this.autoSellTokenBotSettings = data;
          this.$refs.form?.resetValidation();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.processing = false;
        });
    },
    saveSettings(): void {
      this.rules = this.enableRules ? [vr.required, vr.validNumber] : [];
      this.$refs.form?.validate();

      this.$nextTick((): void => {
        if (!this.formIsValid) return;
        this.processing = true;

        saveAutoSellTokenBotSettings(this.autoSellTokenBotSettings)
          .then((data: TAutoSellTokenBotSettings) => {
            this.autoSellTokenBotSettings = data;

            this.$toast.success(
              'AutoSell Token Bot Wallet settings are updated'
            );
            this.$emit('closeDialog');
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
