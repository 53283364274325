







































































import { mapGetters } from 'vuex';
import {
  getBuybacksList,
  getBuybacksCSVReport
} from '@/api/BlockchainWallet';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { capitalization } from '@/helpers/formatString';
import { doDownload } from '@/helpers/download';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import type { AxiosError } from 'axios';
import type { BuybacksListResponse } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

const defaultDate = {
  from: null,
  to: null
};
export default {
  name: 'TokenManagementBuybacksDetails',
  components: { VExtendedDataPicker },
  data: (): any => ({
    options: { ...defaultOptions },
    loading: false,
    loadingCSV: false,
    buybacksData: [],
    total: 0,
    date: { ...defaultDate },
    dateMenu: false,
    headers: [
      {
        text: 'Date',
        value: 'date',
        width: '104px'
      },
      {
        text: 'Play to Earn USDT',
        value: 'playToEarnReward'
      },
      {
        text: 'Hold to Earn Reward USDT',
        value: 'holdToEarnReward'
      },
      {
        text: 'Promo Reward',
        value: 'promoReward'
      },
      {
        text: 'Burn USDT',
        value: 'burn'
      },
      {
        text: 'Buyback Needed USDT',
        value: 'buybackNeeded'
      },
      {
        text: 'Buyback Done USDT',
        value: 'buybackDone'
      }
    ],
    itemsPerPage: [10, 20, 50],
    show: false
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    currentPage(): number {
      return this.options.page - 1;
    },
    watchGroup(): any {
      return [this.options, this.date];
    }
  },
  watch: {
    watchGroup: {
      async handler(): Promise<void> {
        await this.updateBuybacksList();
      },
      deep: true
    },
    operatorId(newVal: number | null): void {
      if (newVal) {
        this.options = { ...defaultOptions };
        this.date = { ...defaultDate };
      }
    },
    show(newVal: number | null): void {
      if (newVal) {
        this.options = { ...defaultOptions };
        this.date = { ...defaultDate };
      }
    }
  },
  methods: {
    capitalization,
    updateBuybacksList(): void {
      if (!this.operatorId || this.loading) return;

      this.loading = true;
      getBuybacksList({
        page: this.currentPage,
        size: this.options.itemsPerPage,
        dateFrom: this.date.from,
        dateTo: this.date.to
      })
        .then((data: BuybacksListResponse): void => {
          const { totalElements, content } = data;
          this.total = totalElements;
          this.buybacksData = content;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });
    },
    closeDialog(): void {
      this.show = false;
    },
    getReport(): void {
      this.loadingCSV = true;

      getBuybacksCSVReport({
        dateFrom: this.date.from,
        dateTo: this.date.to,
        size: this.total
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          doDownload(url, 'buybacks-report.csv');
        })
        .finally(() => {
          this.loadingCSV = false;
        });
    }
  }
};
