



































import { mapState } from 'vuex';

export default {
  name: 'TokenManagementBuybacks',

  computed: {
    ...mapState('app', ['isSuperAdmin'])
  }
};
