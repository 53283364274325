





















































































import { mapActions, mapGetters, mapState } from 'vuex';
import { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import {
  tradingSettings,
  saveTradingSettings,
  savePancakeLimit
} from '@/api/BlockchainWallet';
import { errorToastMessage } from '@/helpers/errorToastMessage';

import type { TradingSettings, ValidationRules } from '@/api/schema';
import type { AxiosError } from 'axios';

interface TradingSettingsState {
  processing: boolean;
  tradingSettings: TradingSettings;
  pancakeLimit: number;
  formIsValid: boolean;
}

export default {
  name: 'TokenManagementTradingSettings',
  props: {
    showDialog: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data: (): TradingSettingsState => ({
    processing: false,
    tradingSettings: {} as TradingSettings,
    pancakeLimit: null,
    formIsValid: true
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('ClientsInfo', ['clientsInfo']),

    pancakeMaxAmount(): number {
      return this.clientsInfo?.pancakeMaxAmount || null;
    },
    rules(): ValidationRules {
      return {
        pancakeLimit: [
          (v: string) => (!v && !v?.length) || vr.validNumber(v),
          (v: string) => (!v && !v?.length) || vr.lte(+v, 1000),
          (v: string) => (!v && !v?.length) || vr.gte(+v, 50)
        ],
        other: [vr.required, vr.validNumber, (v) => vr.gte(v, 0)]
      };
    },
    needUpdatePancakeLimit(): boolean {
      return +this.pancakeLimit !== this.pancakeMaxAmount;
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getTradingSettings();
        }
      },
      immediate: true
    },
    showDialog(status: boolean): void {
      if (!status) {
        this.getTradingSettings();
      }
    },
    pancakeMaxAmount(value: number): void {
      this.pancakeLimit = value;
    }
  },
  methods: {
    ...mapActions('ClientsInfo', ['getClientsInfo']),

    getTradingSettings(): void {
      this.processing = true;
      tradingSettings()
        .then((data: TradingSettings) => {
          this.tradingSettings = data;
          this.$refs.form?.resetValidation();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.processing = false;
        });
    },
    saveSettings(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (!this.formIsValid) return;
        this.processing = true;

        if (this.needUpdatePancakeLimit) {
          savePancakeLimit(+this.pancakeLimit || null).then(() => {
            this.getClientsInfo();
          });
        }

        saveTradingSettings(this.tradingSettings)
          .then((data: TradingSettings) => {
            this.tradingSettings = data;
            this.$toast.success('Trading Wallet settings are updated');
            this.$emit('closeDialog');
          })
          .catch((err: AxiosError): void => {
            errorToastMessage(err);
          })
          .finally((): void => {
            this.processing = false;
          });
      });
    }
  }
};
