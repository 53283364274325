var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"disabled":_vm.loading.list}},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-h6 font-weight-semiblod"},[_vm._v("Transactions")]),_c('v-spacer'),_c('v-extended-data-picker',{attrs:{"max-today":"","range":""},on:{"input":_vm.onDateChange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"width":"96","outlined":"","elevation":"0","loading":_vm.loading.csv,"disabled":_vm.loading.list,"color":"primary"},on:{"click":_vm.getTransactionsCsv}},[_vm._v("CSV"),_c('v-icon',{attrs:{"right":"","size":"18"}},[_vm._v("mdi-file-download-outline")])],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading.list,"items":_vm.transactions,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getFormatDate(item.date)))]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [(item.wallet)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block black--text",staticStyle:{"min-width":"60px"}},'div',attrs,false),on),[_vm._v(_vm._s(_vm.textOverflowMiddle(item.wallet, 6, 6)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.wallet))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copyToClipBoard(item.wallet)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1):_c('span',[_vm._v("N/A")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formattedPrice")(item.amount,_vm.tokenSymbol)))]}},{key:"item.startPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formattedPrice")(item.startPrice,'$', '0,0.[0000000]')))]}},{key:"item.salesPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formattedPrice")(item.salesPrice,'$', '0,0.[0000000]')))]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block black--text",staticStyle:{"min-width":"60px"}},'div',attrs,false),on),[_vm._v(_vm._s(_vm.textOverflowMiddle(item.hash, 6, 6)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.hash))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copyToClipBoard(item.hash)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)]}}])}),_c('v-pagination',{attrs:{"value":_vm.page,"length":_vm.paginationLength,"total-visible":"3"},on:{"input":_vm.changePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }