
























































import * as vr from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { createAddLiquidityPool } from '@/api/BlockchainWallet';

interface CreateLiquidityDataState {
  processing: boolean;
  liquidityData: LiquidityData;
  formIsValid: boolean;
}

interface LiquidityData {
  amountUsdt: string;
  tokenPrice: string;
}

export default {
  name: 'TokenManagementCreateLiquidity',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data: (): CreateLiquidityDataState => ({
    processing: false,
    liquidityData: {} as LiquidityData,
    formIsValid: true
  }),
  computed: {
    rules(): unknown {
      return [vr.required, vr.validNumber, (v) => vr.gte(v, 0)];
    }
  },
  methods: {
    closeDialog(): void {
      this.$emit('closeDialog');
      this.liquidityData = {};
    },
    createLiquidity(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick(() => {
        if (!this.formIsValid) return;

        this.processing = true;

        createAddLiquidityPool(
          this.liquidityData.tokenPrice,
          this.liquidityData.amountUsdt
        )
          .then(() => {
            this.$toast.success('Create Liquidity is success');
            this.closeDialog();
          })
          .catch((err) => {
            errorToastMessage(err);
          })
          .finally(() => {
            this.processing = false;
          });
      });
    }
  }
};
