











































import { mapGetters } from 'vuex';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import formatDate from '@/helpers/formatDate';
import { getLiquidityTransactions } from '@/api/BlockchainWallet';
import { TLiquidityTransactionItem } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['createdAt'],
  sortDesc: [false]
};

export default {
  name: 'TokenManagementLiquidityPoolTable',
  components: {},
  data(): any {
    return {
      liquidityData: [],
      isLoading: false,
      options: { ...defaultOptions },
      headers: [
        { text: 'Date', value: 'createdAt', width: '25%' },
        { text: 'Amount', value: 'amountUsdt', width: '25%' },
        { text: `Transaction Type`, value: 'type', width: '25%' },
        { text: 'Hash', value: 'hash', width: '25%' }
      ]
    };
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getLiquidityPoolData();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapGetters('SuperAdmin', ['isCreateLiquidityPool'])
  },
  methods: {
    formatDate,
    getLiquidityPoolData(): Promise<void> {
      this.loading = true;

      if (!this.operatorId) return;

      return getLiquidityTransactions({
        size: this.options.itemsPerPage,
        page: this.options.page - 1,
        sort: this.options.sortBy[0],
        order: this.options.sortDesc[0] ? 'DESC' : 'ASC'
      })
        .then((data: TLiquidityTransactionItem[]): void => {
          this.liquidityData = data;
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.loading = false;
        });
    }
  }
};
