











































































import { mapState } from 'vuex';
import VTabsHash from '@/components/VTabsHash.vue';
import { TTabsItem, BlockchainWalletType } from '@/api/schema';

import TokenManagementWalletCard from '../token-management-wallets/TokenManagementWalletCard.vue';

import TokenManagementStatistic from '../TokenManagementStatistic.vue';

import TokenManagementTradingSettings from '../token-management-trading/TokenManagementTradingSettings.vue';
import TokenManagementTradingDetails from '../token-management-trading/TokenManagementTradingDetails.vue';

import TokenManagementBuybackVolume from '../token-management-buyback/TokenManagementBuybackVolume.vue';
import TokenManagementBuybackSettings from '../token-management-buyback/TokenManagementBuybackSettings.vue';
import TokenManagementBuybacksDetails from '../token-management-buyback/TokenManagementBuybackDetails.vue';

import TokenManagementLiquidityPoolTab from '../token-management-liquidity/TokenManagementLiquidityPoolTab.vue';
import TokenManagementLiquiditySettings from '../token-management-liquidity/TokenManagementLiquiditySettings.vue';

import TokenManagementAutoSellTokenBotSettings from '../token-management-auto-sell-token-bot/TokenManagementAutoSellTokenBotSettings.vue';
import TokenManagementAutoSellTokenBotInfo from '../token-management-auto-sell-token-bot/TokenManagementAutoSellTokenBotInfo.vue';
import TokenManagementAutoSellTokenBotTransactions from '../token-management-auto-sell-token-bot/TokenManagementAutoSellTokenBotTransactions.vue';

export default {
  components: {
    TokenManagementTradingDetails,
    TokenManagementBuybacksDetails,
    TokenManagementBuybackVolume,
    VTabsHash,
    TokenManagementStatistic,
    TokenManagementLiquidityPoolTab,
    TokenManagementWalletCard,
    TokenManagementTradingSettings,
    TokenManagementBuybackSettings,
    TokenManagementLiquiditySettings,
    TokenManagementAutoSellTokenBotSettings,
    TokenManagementAutoSellTokenBotInfo,
    TokenManagementAutoSellTokenBotTransactions
  },

  data(): any {
    return {
      BlockchainWalletType,
      currentTab: 0
    };
  },

  computed: {
    ...mapState('app', ['isSuperAdmin']),

    tabs(): TTabsItem[] {
      return [
        {
          id: BlockchainWalletType.trading,
          text: 'Trading Operations',
          visible: true
        },
        {
          id: BlockchainWalletType.buyback,
          text: 'Buyback Operations',
          visible: true
        },
        {
          id: BlockchainWalletType.liquidity,
          text: 'Liquidity Pool',
          visible: true
        },
        {
          id: BlockchainWalletType.autoSellTokenBot,
          text: 'AutoSell Token Bot',
          visible: this.isSuperAdmin
        }
      ].filter(({ visible }) => visible);
    }
  }
};
