var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs-hash',{staticClass:"mb-6",attrs:{"tabs":_vm.tabs,"background-color":"transparent"},scopedSlots:_vm._u([{key:"tab",fn:function(ref){
var text = ref.text;
return [_c('v-tab',[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}}),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{key:_vm.BlockchainWalletType.trading},[_c('TokenManagementWalletCard',{staticClass:"mb-6",attrs:{"title":"Trading Wallet","type":_vm.BlockchainWalletType.trading,"error-message":"Not enough funds to do trading transactions. Please top up your Operator Balance or your Trading wallet."},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
var show = ref.show;
var close = ref.close;
return [_c('TokenManagementTradingSettings',{attrs:{"showDialog":show},on:{"closeDialog":close}})]}}])}),_c('v-card',[_c('TokenManagementStatistic',{staticClass:"mb-5",attrs:{"type":_vm.BlockchainWalletType.trading},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('TokenManagementTradingDetails')]},proxy:true}])})],1)],1),_c('v-tab-item',{key:_vm.BlockchainWalletType.buyback},[_c('TokenManagementWalletCard',{staticClass:"mb-6",attrs:{"title":"Buyback Wallet","type":_vm.BlockchainWalletType.buyback,"error-message":"Not enough funds to do buybacks. Please top up your Operator Balance or your Buyback wallet."},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
var show = ref.show;
var close = ref.close;
return [_c('TokenManagementBuybackSettings',{attrs:{"showDialog":show},on:{"closeDialog":close}})]}}])}),_c('v-card',[_c('TokenManagementStatistic',{staticClass:"mb-5",attrs:{"title":"Buyback Stats","type":_vm.BlockchainWalletType.buyback},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('TokenManagementBuybacksDetails')]},proxy:true},{key:"volume",fn:function(){return [_c('TokenManagementBuybackVolume')]},proxy:true}])})],1)],1),_c('v-tab-item',{key:_vm.BlockchainWalletType.liquidity},[_c('TokenManagementWalletCard',{staticClass:"mb-6",attrs:{"title":"Liquidity Wallet","type":_vm.BlockchainWalletType.liquidity},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
var show = ref.show;
var close = ref.close;
return [_c('TokenManagementLiquiditySettings',{attrs:{"showDialog":show},on:{"closeDialog":close}})]}}])}),_c('TokenManagementLiquidityPoolTab')],1),_c('v-tab-item',{key:_vm.BlockchainWalletType.autoSellTokenBot},[_c('TokenManagementWalletCard',{staticClass:"mb-6",attrs:{"title":"AutoSell Token Bot Wallet","type":_vm.BlockchainWalletType.autoSellTokenBot},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
var show = ref.show;
var close = ref.close;
return [_c('TokenManagementAutoSellTokenBotSettings',{attrs:{"showDialog":show},on:{"closeDialog":close}})]}}])}),_c('TokenManagementAutoSellTokenBotInfo',{staticClass:"mb-6"}),_c('TokenManagementAutoSellTokenBotTransactions')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }