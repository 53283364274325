




























































import { mapState } from 'vuex';
import type { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import { capitalization } from '@/helpers/formatString';
import { roundNumber } from '@/helpers/roundNumber';
import BSCLogo from '@/assets/images/payments/bsc.svg?inline';
import { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';

export default {
  name: 'TokenManagementWithdrawDialog',
  components: {
    BSCLogo
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    from: {
      type: String as PropType<string>,
      default: (): string => 'MASTER'
    }
  },
  data(): any {
    return {
      formIsValid: true,
      min: 1000,
      max: 1000000000,
      formData: {
        amount: null,
        address: null
      }
    };
  },
  computed: {
    ...mapState('Dashboard', ['loading', 'operatorWallet']),
    ...mapState('TokensInfo', {
      currency: (state: TokensInfoState) => state.tokenPrice.currency
    }),
    maxAmount(): number {
      const balance = Math.floor(
        +this.operatorWallet?.[this.from]?.balance || 0
      );
      return balance > this.max ? this.max : balance;
    },
    tokensFieldHint(): string {
      const min = this.$options.filters
        .numeralSpaces(this.min, '0,0')
        .replace(/,/g, ' ');
      const max = this.$options.filters
        .numeralSpaces(this.maxAmount, '0,0')
        .replace(/,/g, ' ');
      return `Min ${min} ${this.walletCurrency} Max ${max} ${this.walletCurrency}`;
    },
    walletCurrency(): string {
      return this.operatorWallet?.[this.from]?.currency || '';
    },
    rules(): any {
      return {
        amount: [
          (v) => vr.required(v),
          (v) => vr.validNumber(v),
          (v) => vr.integer(v),
          (v) => vr.positiveNumber(v),
          (v) => vr.gte(v, this.min),
          (v) => vr.lte(v, this.maxAmount)
        ],
        address: [vr.required, vr.metaMaskPublicKey]
      };
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value(): void {
      this.$refs.form?.resetValidation();
      this.$refs.form?.reset();
    }
  },
  methods: {
    roundNumber,
    capitalization,
    submitHandler(): void {
      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', {
            ...this.formData,
            tokenSymbol: this.currency
          });
        }
      });
    }
  }
};
