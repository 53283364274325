

















































import { DateTime } from 'luxon';
import BaseDialog from '@/components/BaseDialog.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { DataTableHeader } from 'vuetify';
import { doDownload } from '@/helpers/download';
import {
  getTradingDetailsList,
  getTradingDetailsCSVReport
} from '@/api/BlockchainWallet';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

const defaultDate = {
  from: DateTime.utc().minus({ day: 1, month: 1 }).toFormat('yyyy-LL-dd'),
  to: DateTime.utc().minus({ day: 1 }).toFormat('yyyy-LL-dd')
};

export default {
  name: 'TokenManagementTradingDetails',
  components: {
    BaseDialog,
    VExtendedDataPicker
  },

  data(): any {
    return {
      openModal: false,
      options: { ...defaultOptions },
      date: { ...defaultDate },
      rows: [],
      totalElements: 0,
      loading: {
        table: false,
        csv: false
      }
    };
  },

  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Date',
          value: 'date',
          width: '104px'
        },
        {
          text: 'Deposit USDT',
          value: 'deposit'
        },
        {
          text: 'Deposit Pancake USDT',
          value: 'depositPancake'
        },
        {
          text: 'Withdraw USDT',
          value: 'withdraw'
        },
        {
          text: 'Withdraw pancake USDT',
          value: 'withdrawPancake'
        }
      ];
    },

    requestParams(): any {
      return {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        dateFrom: this.date.from,
        dateTo: this.date.to
      };
    }
  },

  watch: {
    openModal(open: boolean): void {
      open ? this.getDetailsList() : this.resetData();
    },

    requestParams: 'getDetailsList'
  },

  methods: {
    getDetailsList(): void {
      if (!this.openModal || this.loading.table) return;

      this.loading.table = true;

      getTradingDetailsList(this.requestParams)
        .then((response) => {
          this.rows = response.content;
          this.totalElements = response.totalElements;
        })
        .finally(() => {
          this.loading.table = false;
        });
    },

    getReport(): void {
      this.loading.csv = true;

      getTradingDetailsCSVReport({
        dateFrom: this.date.from,
        dateTo: this.date.to,
        size: this.totalElements
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          doDownload(url, 'trading-report.csv');
        })
        .finally(() => {
          this.loading.csv = false;
        });
    },

    resetData(): void {
      this.options.page = 1;
      this.date = { ...defaultDate };

      setTimeout(() => {
        this.rows = [];
      }, 300);
    }
  }
};
