var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table-with-pagination',{attrs:{"headers":_vm.headers,"items-per-page":_vm.options.itemsPerPage,"options":_vm.options,"server-items-length":3,"loading":_vm.isLoading,"items":_vm.liquidityData,"sort-by":"createdAt"},on:{"update:options":[function($event){_vm.options=$event},_vm.getLiquidityPoolData]},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.createdAt, 'yyyy-LL-dd HH:mm:ss', false, false)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amountUsdt,'0,0.[00]'))+" USDT")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","size":"24","color":item.type === 'add' ? 'success' : 'error'}},[_vm._v(_vm._s(item.type === 'add' ? 'mdi-arrow-right-thin': 'mdi-arrow-left-thin'))]),_vm._v(_vm._s(item.type === 'add' ? 'Add liquidity' : 'Remove liquidity'))]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [(item.hash)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.hash)),_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","href":("https://bscscan.com/tx/" + (item.hash)),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1):_c('span',{staticClass:"text-no-wrap"},[_vm._v("N/A")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }