var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1226","persistent":"","no-click-animation":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Details")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-5 px-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Details")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex justify-end pa-4"},[_c('v-extended-data-picker',{attrs:{"range":"","max-yesterday":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"loading":_vm.loadingCSV,"color":"primary","outlined":""},on:{"click":_vm.getReport}},[_vm._v("CSV"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-download-outline")])],1)],1),_c('v-card-text',{staticClass:"pt-5 px-0 pb-0"},[_c('v-data-table-with-pagination',{attrs:{"headers":_vm.headers,"items":_vm.buybacksData,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"disable-sort":"","height":"554px","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.deposit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.deposit,'0,0.[00]')))]}},{key:"item.holdToEarnReward",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.holdToEarnReward,'0,0.[00]')))]}},{key:"item.playToEarnReward",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.playToEarnReward,'0,0.[00]'))+" "+_vm._s(_vm._f("numeralSpaces")(item.transferOut,'0,0.[00]')))]}},{key:"item.promoReward",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.promoReward,'0,0.[00]')))]}},{key:"item.burn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.burn,'0,0.[00]')))]}},{key:"item.buybackNeeded",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.buybackNeeded,'0,0.[00]')))]}},{key:"item.buybackDone",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.buybackDone,'0,0.[00]')))]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }