var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table-with-pagination',{attrs:{"headers":_vm.tableHeaders,"items-per-page":_vm.options.itemsPerPage,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.transactions,"sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.trade",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex"},[(item.trade === 'IN')?_c('v-icon',{staticClass:"success--text mr-1",domProps:{"textContent":_vm._s('mdi-arrow-left-thin')}}):_c('v-icon',{staticClass:"warning--text mr-1",domProps:{"textContent":_vm._s('mdi-arrow-right-thin')}}),_c('span',{staticClass:"token-management__transaction-item-status-text"},[_vm._v(_vm._s(item.trade === 'IN' ? 'In' : 'Out'))])],1)]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.feeBNB,'0,0.[00]'))+" BNB")])]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [(item.hash)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.hash)),_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","href":(" https://bscscan.com/tx/" + (item.hash)),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1):_c('span',{staticClass:"text-no-wrap"},[_vm._v("N/A")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amountUsd,'0,0'))+" USD")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.date, 'yyyy-LL-dd HH:mm:ss', null, false)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }