










import { mapActions, mapState, mapGetters } from 'vuex';
import type { ClientsInfoState } from '@/store/modules/clients-info/ClientsInfoState';

import TokenManagementBuybacks from './token-management-buyback/TokenManagementBuybacks.vue';
import TokenManagementMasterWallet from './token-management-master-wallet/TokenManagementMasterWallet.vue';
import TokenManagementWallets from './token-management-wallets/TokenManagementWallets.vue';

export default {
  name: 'TokenManagementView',
  components: {
    TokenManagementWallets,
    TokenManagementMasterWallet,
    TokenManagementBuybacks
  },
  data(): any {
    return {
      polling: null
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('TokensInfo', ['frozenTokens', 'tokenPrice']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('ClientsInfo', {
      loadingInfo: (state: ClientsInfoState) => {
        return state.loadingInfo;
      },
      currency: (state: ClientsInfoState) => {
        return state.clientsInfo?.adminCurrencyTicker || 'USD';
      },
      isListed: (state: ClientsInfoState) =>
        !!state.clientsInfo?.pancakeSwapUrl
    })
  },
  watch: {
    operatorId: 'loadData',
    currency: 'updatePrice',
    loadingInfo: {
      handler(loading: boolean): void {
        if (loading) return;

        if (!this.isListed && !this.isSuperAdmin) {
          this.$router.push('/');
          return;
        }
        this.polling = setInterval(this.loadData, 60000);
      },
      immediate: true
    }
  },

  mounted(): void {
    this.loadData();
  },

  beforeDestroy(): void {
    clearInterval(this.polling);
  },

  methods: {
    ...mapActions('TokensInfo', [
      'getFrozenTokensData',
      'getTokenPriceData',
      'getTokenInfoData'
    ]),
    ...mapActions('BlockchainWallet', ['getTransactions', 'getWallet']),

    loadData(): void {
      clearInterval(this.polling);
      if (!this.operatorId) return;

      this.getFrozenTokensData();
      this.updatePrice(this.operatorId);
      this.getTokenInfoData();
      this.getWallet(this.operatorId);
    },

    updatePrice(update: boolean): void {
      if (!update) return;

      this.getTokenPriceData();
    }
  }
};
