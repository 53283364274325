










































import type { PropType } from 'vue';
import StatsCard from '@/components/StatsCard.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import TokenManagementTransactionsTable from './TokenManagementTransactionsTable.vue';
import { BlockchainWalletType } from '@/api/schema';
import { toTitleCase } from '@/helpers/formatString';

import {
  getBuybacksTransactionCSVReport,
  getTradingTransactionCSVReport
} from '@/api/BlockchainWallet';

import { doDownload } from '@/helpers/download';

import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';

export default {
  name: 'TokenManagementStatistic',
  components: {
    StatsCard,
    VExtendedDataPicker,
    TokenManagementTransactionsTable
  },
  props: {
    type: {
      type: String as PropType<BlockchainWalletType>,
      default: (): string => BlockchainWalletType.trading,
      required: true
    }
  },
  data(): any {
    return {
      date: DateTime.utc().toFormat('yyyy-LL-dd'),
      loadingCSV: false
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),

    transactionsType(): string {
      return this.type.toLowerCase();
    },

    tabTitle(): string {
      return toTitleCase(`${this.transactionsType} Stats`);
    },

    transactionsData(): any {
      return this.$store.state.BlockchainWallet.summary[
        this.transactionsType
      ];
    },
    totalFeeBNB(): number {
      return this.$options.filters.numeralSpaces(
        this.transactionsData?.totalFeeBNB,
        '0.[00]'
      );
    },
    totalFee(): number {
      return this.$options.filters.numeralSpaces(
        this.transactionsData?.totalFeeUsd,
        '0.[00]'
      );
    },
    totalAmountUsd(): number {
      return this.$options.filters.numeralSpaces(
        this.transactionsData?.totalAmountUsd,
        '0.[00]'
      );
    },
    hideColumns(): string[] {
      return this.type === BlockchainWalletType.buyback ? ['trade'] : [];
    }
  },

  methods: {
    getReport(): void {
      this.loadingCSV = true;

      const requestMap = {
        [BlockchainWalletType.buyback]: getBuybacksTransactionCSVReport,
        [BlockchainWalletType.trading]: getTradingTransactionCSVReport
      };

      const handler = requestMap[this.type];

      handler({ date: this.date })
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const fileName = `${this.transactionsType}-transaction-report_${this.date}.csv`;
          doDownload(url, fileName);
        })
        .finally(() => {
          this.loadingCSV = false;
        });
    }
  }
};
